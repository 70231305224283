
import "../styles/layouts/footer.css"

export const Footer = ()=>{
    return( 
    <footer className="flex-column-center pd-sm">
        <p className="mg-bottom-xsm">Copyright © 2024. All rights reserved.</p>
     
        
  </footer>
  );
}
